<template>
  <div>
    <h4>공지 추가</h4>

    <el-button type="primary" @click="$router.push({ name: 'announcements' })">
      공지 리스트로 돌아가기
    </el-button>

    <el-input v-model="title" />

    <QuillEditor ref="editor" :modules="modules" theme="snow" toolbar="full" />

    <el-button type="primary" @click="save">저장</el-button>
  </div>
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import BlotFormatter from "quill-blot-formatter";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import AnnouncementService from "../services/AnnouncementService";

export default {
  components: {
    QuillEditor,
  },
  setup: () => {
    const modules = {
      name: "blotFormatter",
      module: BlotFormatter,
      options: {},
    };
    return { modules };
  },
  data() {
    return {
      announcementId: this.$route.params.id,
      isEdit: !!this.$route.params.id,
      title: "",
    };
  },
  methods: {
    async save() {
      if (!this.title) {
        this.$message.error(`공지 제목을 입력해주세요.`);
        return;
      }

      try {
        const content = this.$refs.editor.getHTML();
        console.log(content);

        if (this.isEdit) {
          await AnnouncementService.update(this.announcementId, {
            title: this.title,
            content,
          });
          this.$message.success("공지가 업데이트 되었습니다.");
        } else {
          await AnnouncementService.create({
            title: this.title,
            content,
          });
          this.$message.success("공지가 추가 되었습니다.");
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    if (!this.isEdit) {
      return;
    }

    try {
      const resp = await AnnouncementService.get(this.announcementId);
      const announcement = resp.data;
      console.log("announcement: ", announcement);

      this.title = announcement.title;
      this.$refs.editor.setHTML(announcement.content);
    } catch (e) {
      console.log(e);
    }
  },
};
</script>